import React from 'react';

import Title from '../components/Title';
import Layout from '../components/Layout';
import Person from '../components/Person';

import Mike from '../../assets/images/team/Mike.jpg';
import Paul from '../../assets/images/team/Paul.jpg';
import Gail from '../../assets/images/team/Gail.jpg';

const title = 'The Founders';

const Founders = () => (
  <Layout title={title}>
    <div className="c-content-box c-size-md">
      <div className="container">
        <Title main={title} />
        <div className="row display-flex">
          <Person
            name="Paul Hobson"
            image={Paul}
            company="Calf Hey Group"
            position="Director"
            md={6}
            bio={`
              Paul is a company director of the Calf Hey Group. Paul qualified in Huddersfield as an architectural technician before moving into the manufacturing industry. He spent a number of years running a manufacturing company based in West Yorkshire.<br /><br />
              From 1982, he built up a successful printing business before developing his own graphic design studio. He met Mike in 1986 through business and found they both shared the same Christian faith. Paul joined forces with Mike at Calf Hey shortly after meeting together.<br /><br />
              More recently, Paul has been involved in expanding the group company along with securing the purchase of the premises that Calf Hey Design now operate from.
              <br /><br />Paul enjoys golf and walking with his wife Gill.
            `}
          />
          <Person
            name="Mike Roddis"
            image={Mike}
            company="Calf Hey Group"
            position="Consultant"
            md={6}
            bio={`
              Mike is now a company consultant to the Calf Hey Group.<br /><br />
              Mike has spent most of his working life in various sales roles. His background has predominantly been with printing and packaging companies.<br /><br />
              In 1986, he started Calf Hey Design working from a very small unit in a rural village outside of Huddersfield. At the time, Paul began producing all Mike's artwork requirements.
              Joining as business partners enabled Mike and Paul to develop Calf Hey into a thriving and competitive label company.<br /><br />
              30 years on, Mike has retired from the group but remains active as a financial & business development consultant.
            `}
          />
        </div>
      </div>
    </div>

    <div className="c-content-box c-size-md">
      <div className="container">
        <Title main="Retired Directors" />
        <div className="row display-flex">
          <Person
            name="Gail Hughes"
            image={Gail}
            company="Calf Hey Design"
            position="Company Director"
            md={6}
            bio={`
            We would like to thank Gail for her loyalty over the years. Gail has worked for the company for over 25 years, starting in the office and progressing to the role of company operations director in 2015. She has worked very hard to promote the growth of the business over recent years and has run an excellent team, including the training of Ben Hobson and Caitlin Chapman.<br /><br />Gail retires from Calf Hey Design at the end of 2019 and will be sadly missed.
            `}
          />
        </div>
      </div>
    </div>
  </Layout>
);

export default Founders;
