import React from 'react';

const styleContainer = { background: '#f9f7f7', height: '100%', borderRadius: '14px 14px 0 0' };

const styleImage = {
  borderRadius: '14px 14px 0 0'
};

const Person = props => (
  <div className={`col-md-${props.md} col-sm-${props.sm} c-margin-b-30`}>
    <div className="c-content-person-1 c-option-2" style={styleContainer}>
      <div className="c-caption">
        <img style={styleImage} className="img-responsive" src={props.image} alt={props.name} />
      </div>
      <div className="c-body">
        <div className="c-head">
          <div className="c-name c-font-uppercase c-font-bold">{props.name}</div>
          {props.email && (
            <ul className="c-socials c-theme-ul">
              <li>
                <a href={`mailto:${props.email}`} target="_blank" rel="noopener noreferrer">
                  <i className="icon-envelope" />
                </a>
              </li>
            </ul>
          )}
        </div>
        <div className="c-position">
          {props.position} <br /> {props.company}
        </div>
        <p dangerouslySetInnerHTML={{ __html: props.bio }} />
      </div>
    </div>
  </div>
);

Person.defaultProps = {
  md: 4,
  sm: 6
};

export default Person;
