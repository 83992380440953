import React from 'react';

const Title = props => (
  <div className="c-content-title-1">
    <h1 className="c-font-uppercase c-center c-font-bold c-font-white">{props.main}</h1>
    <div className="c-line-center c-theme-bg" />
    <p className="c-font-center" dangerouslySetInnerHTML={{ __html: props.helper }} />
  </div>
);

export default Title;
